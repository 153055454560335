<template>
  <div class="check-nursery-inscriptions" v-if="canAccess">
    <page-header
      title="Vérification des inscriptions"
      icon="fa fa-baby-carriage"
      :links="getLinks()"
    ></page-header>
    <div>
      <b-row class="row-line">
        <b-col cols="9">
          <date-frame-selector
            :start-date="startDate"
            :end-date="endDate"
            @change="onDateRangeChanged"
          >
          </date-frame-selector>
          <div><a href @click.prevent="onSetLastMonthDates()">Mois écoulé</a></div>
          <div><a href @click.prevent="onSetCurrentMonthDates()">Mois en cours</a></div>
        </b-col>
        <b-col cols="3" class="text-right">
          <a class="btn btn-primary" href @click.prevent="loadData()" :class="{ disabled: !isActive }">
            <i class="fa fa-search"></i> Voir
          </a>
        </b-col>
      </b-row>
      <div class="page-sub-header" v-if="loaded">
        <b-row>
          <b-col cols="3">
            <b-form-checkbox id="showMissing" v-model="showMissing">
              <counter-label :counter="missingCount" label="Pointage manquant" label-n="Pointages manquants">
              </counter-label>
            </b-form-checkbox>
          </b-col>
          <b-col cols="3">
            <b-form-checkbox id="showAbsence" v-model="showAbsence">
              <counter-label :counter="absenceCount" label="Absence">
              </counter-label>
            </b-form-checkbox>
          </b-col>
          <b-col cols="6">
            <b-form-input id="nameFilter" v-model="nameFilter" placeholder="Nom d'un enfant">
            </b-form-input>
          </b-col>
        </b-row>
      </div>
      <loading-gif :loading-name="checkNurseryLoading"></loading-gif>
      <div ref="printMe" v-if="!isLoading(checkNurseryLoading)">
        <div v-if="!loaded" class="empty-text">
          Sélectionnez une période puis cliquez sur "Voir"
        </div>
        <div v-else-if="inscriptions.length === 0" class="empty-text">
          Aucune absence ou pointage manquant
        </div>
        <div v-else-if="filteredInscriptions.length === 0" class="empty-text">
          Aucune inscription ne correspond au filtre
        </div>
        <div v-if="filteredInscriptions.length" ref="excelTable">
          <b-row>
            <b-col>
              <table class="table small-text table-striped table-bordered">
                <tr>
                  <th>Date</th>
                  <th colspan="2">Enfant</th>
                  <th v-if="nurseries.length > 1">Accueil</th>
                  <th>Absence</th>
                  <th>Raison</th>
                  <th>Arrivée</th>
                  <th>Départ</th>
                </tr>
                <tr
                  v-for="ins of filteredInscriptions"
                  :key="ins.id"
                  :class="{ missing: isClockingMissing(ins), valid: isClockingValid(ins), }"
                >
                  <td>{{ ins.date | dateToString('ddd LL') }}</td>
                  <td>
                    <a href @click.prevent="showEntitySidebar(ins.entity)">
                      {{ ins.individual.lastName }}
                    </a>
                  </td>
                  <td>
                    <a href @click.prevent="showIndividualSidebar(ins.individual, true)">
                      {{ ins.individual.firstName }}
                    </a>
                  </td>
                  <td v-if="nurseries.length > 1">{{ ins.nursery.name }}</td>
                  <td>{{ ins.getAbsence() }}</td>
                  <td>
                    <span v-if="ins.absenceReason">{{ ins.absenceReason.name }}</span>
                  </td>
                  <td>{{ getArrivedAt(ins) }}</td>
                  <td>{{ getLeftAt(ins) }}</td>
                  <td>
                    <a href @click.prevent="selectInscription(ins)">
                      <i class="fa fa-edit"></i>
                    </a>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <nursery-set-inscription-clocking-modal
      id="bv-ins-clocking-modal3"
      :inscription="selectedInscription"
      @confirmed="patchInscription($event)"
      @split="splitedInscription"
    ></nursery-set-inscription-clocking-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment/moment'
import { mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import CounterLabel from '@/components/Controls/CounterLabel'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import PageHeader from '@/components/Layout/PageHeader'
import NurserySetInscriptionClockingModal from '@/components/Nursery/NurserySetInscriptionClockingModal.vue'
import { dateToString, currency } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { ActivitiesMixin } from '@/mixins/activities'
import { monthsAgo, getMonthRange } from '@/utils/dates'
import { BackendApi, openDocument } from '@/utils/http'
import { makeNursery, makeNurseryInscription, NurseryAbsence } from '@/types/nursery'

export default {
  name: 'CheckNurseryInscriptions',
  mixins: [BackendMixin, ActivitiesMixin],
  components: {
    CounterLabel,
    NurserySetInscriptionClockingModal,
    DateFrameSelector,
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      checkNurseryLoading: 'check-nursery',
      nurseries: [],
      inscriptions: [],
      startDate: null,
      endDate: null,
      nameFilter: '',
      showAbsence: true,
      showMissing: true,
      selectedInscription: null,
      loaded: false,
    }
  },
  watch: {
  },
  computed: {
    canAccess() {
      return this.hasPerm('nursery.change_nursery')
    },
    isActive() {
      return this.startDate && this.endDate
    },
    missingCount() {
      return this.inscriptions.filter(this.isClockingMissing).length
    },
    absenceCount() {
      return this.inscriptions.filter(ins => ins.absence !== NurseryAbsence.None).length
    },
    filteredInscriptions() {
      let inscriptions = this.inscriptions
      if (this.nameFilter) {
        const nameFilter = this.nameFilter.toLowerCase()
        inscriptions = inscriptions.filter(
          ins => ins.individual.lastAndFirstName().toLowerCase().indexOf(nameFilter) >= 0
        )
      }
      if (!this.showMissing) {
        inscriptions = inscriptions.filter(ins => !this.isClockingMissing(ins))
      }
      if (!this.showAbsence) {
        inscriptions = inscriptions.filter(ins => ins.absence === NurseryAbsence.None)
      }
      return inscriptions
    },
  },
  async mounted() {
    await this.loadNurseries()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    async loadNurseries() {
      this.startLoading(this.loadingName)
      let url = '/nursery/api/nurseries/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.nurseries = resp.data.map(makeNursery)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadData() {
      this.inscriptions = []
      this.startLoading(this.checkNurseryLoading)
      this.loaded = false
      let url = '/nursery/api/check-inscriptions/'
      const backendApi = new BackendApi('post', url)
      try {
        const data = {
          'start_date': dateToString(this.startDate, 'YYYY-MM-DD'),
          'end_date': dateToString(this.endDate, 'YYYY-MM-DD'),
        }
        const resp = await backendApi.callApi(data)
        this.inscriptions = resp.data.map(makeNurseryInscription)
        this.loaded = true
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.checkNurseryLoading)
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/?landscape=1'
      const docSlug = 'verification-creche-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'verification-creche-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.filteredInscriptions.length) ? 'btn-secondary' : 'btn-secondary disabled',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (this.filteredInscriptions.length) ? 'btn-secondary' : 'btn-secondary disabled',
        }
      ]
    },
    onDateRangeChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
    },
    onSetLastMonthDates() {
      const lastMonth = monthsAgo(moment(), -1)
      const range = getMonthRange(lastMonth)
      if (range.length >= 2) {
        this.startDate = range[0]
        this.endDate = range[1]
      }
    },
    onSetCurrentMonthDates() {
      const range = getMonthRange(moment())
      if (range.length >= 2) {
        this.startDate = range[0]
        this.endDate = range[1]
      } else {
        this.startDate = null
        this.endDate = null
        this.loaded = false
      }
    },
    getArrivedAt(ins) {
      if (ins.arrivedAt) {
        return ins.arrivedAt
      } else {
        if (ins.absence === NurseryAbsence.None) {
          return 'Manquant'
        }
      }
      return ''
    },
    getLeftAt(ins) {
      if (ins.leftAt) {
        return ins.leftAt
      } else {
        if (ins.absence === NurseryAbsence.None) {
          return 'Manquant'
        }
      }
      return ''
    },
    isClockingMissing(ins) {
      if (ins.absence === NurseryAbsence.None) {
        return ((!ins.arrivedAt) || (!ins.leftAt))
      }
      return false
    },
    isClockingValid(ins) {
      return ((ins.absence === NurseryAbsence.None) && ins.arrivedAt && ins.leftAt)
    },
    selectInscription(inscription) {
      this.selectedInscription = inscription
      this.$bvModal.show('bv-ins-clocking-modal3')
    },
    patchInscription(event) {
      const index = this.inscriptions.map(elt => elt.id).indexOf(event.inscription.id)
      if (index >= 0) {
        this.inscriptions[index] = event.inscription
        this.inscriptions = this.inscriptions.concat([])
      }
    },
    splitedInscription(event) {
      const inscription = event.inscriptions[0]
      const inscriptionIds = this.inscriptions.map(elt => elt.id)
      for (const ins of event.inscriptions) {
        const index = inscriptionIds.indexOf(ins.id)
        if (index >= 0) {
          this.inscriptions[index] = ins
        } else {
          this.inscriptions.push(ins)
        }
      }
      this.inscriptions = this.inscriptions.concat([])
    },
  },
}
</script>

<style lang="less" scoped>
.page-sub-header {
  background: #ccc;
  padding: 10px;
  margin-bottom: 10px;
}
.table tr.missing td {
  background: #f1b0b7;
}
.table tr:nth-child(odd).missing td {
  background: #bf8d93;
}
.table tr.valid td {
  background: #bcffbd;
}
.table tr:nth-child(odd).valid td {
  background: #aae3ab;
}

</style>
